:root{
  --azul:#3778F0;
  --darkish-blue:#003f91;
  --green-apple:#67c91e;
  --pale-gray:#f2f7fa;
  --twilight:#6461a0;
  --light-gold:#ffc857;
  --orange-pink:#ff715b;
  --lipstick:#e72e52;
}

* {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.app {
  display: flex;
  height: 100vh;
  overflow-y: hidden;
}

.left {
  width: 25%;
  background-color: #f8fafc;
  height:100%;
}

.middle {
  width: 50%;
  background-color:white;

}

.right {
  width: 25%;
  background-color: #f8fafc;
}


@media (max-width: 768px) {
  .left {
      display: none;
  }

  .right {
      display: none;
  }

  .middle {
    width: 100%;
    margin-left: 0px;
  }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}