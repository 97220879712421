.jenna-bot-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-size: 2em;
    background-color: white;
    border: 1px solid var(--azul);
    background-image: url("/images/robot.svg");
    background-position: center;
    margin-left: 1em;
}