.jenna-loader {
  width: 100px;
  height: 100px;
  top: -20px;
  position: relative;

  &-circular {
    width: 120px;
    height: 120px;
    position: relative;
    animation: rotate 2s linear infinite;

    &-path {
      stroke-linecap: round;
      stroke: #347af0;
      stroke-dasharray: 1, 600;
      stroke-dashoffset: 0;
      animation: dash 2s ease-in-out infinite;
    }
  }

  &-drop {
    position: absolute;
    top: 35%;
    left: 39%;
    stroke-width: 2;
    animation: drop 2s ease-in-out infinite;
  }
}



@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 400;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 200, 400;
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dasharray: 200, 400;
    stroke-dashoffset: -300;
  }
}

@keyframes drop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}