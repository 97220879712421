.jenna-user-avatar {
  position: relative;

  &:hover > img {
    transform: scale(0.99);
  }

  &-input {
    display:none;
    visibility:hidden;
  }

  &-image { 
    width: inherit;
    height: inherit;
    border-radius: 50%;
    position: absolute;
    top: 0;
  }

  &-hover {
    width: inherit;
    height: inherit;
    border-radius: 50%;
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    background-position: 50% 60%;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .uploaded:hover {
    border: 1px solid #ef5564;
    background-image: url('/images/delete.svg');
  }

  .placeholder:hover {
    background-color: #ffffff81;
    border: 1px solid var(--azul);
    background-image: url('/images/upload.svg');
  }
  
  &-spinner {
    width: inherit;
    height: inherit;
    border: 2px solid #f7f7f7;
    border-top: 2px solid var(--azul);
    border-radius: 50%;
    position: absolute;
    top: 0;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
