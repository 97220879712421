.jenna-cv {
    display: flex;

    input {
        display: none;
    }

    &-button {
        max-width: 150px;
        padding: 8px 20px 8px 20px;
        margin: 0 14px 8px 15px;
        border-radius: 35px;
        line-height: 2;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        background-color: var(--azul);
        color: white;
    }

    .uploading {
        background-color: #a1c0dd;
    }

    &-message {
        display: flex;
        align-items: center;

        &-text {
            width: 15.8em;
            height: 9em;
            margin: 0 12px 13px 15px;
            border-radius: 26px;
            border: solid 1px #f2f2f2;
            display: inline-block;
            background-color: #ffffff;
            background-position: center 5.2em;
            background-repeat: no-repeat;
            background-image: url('/images/cv.svg');
            cursor: pointer;

            p {
                padding-top: 0.7em;
                font-size: 14px;
                text-align: center;
                color: #1a1a1a;
                text-align: center;
                padding-left: 0.7em;
                padding-right: 0.7em;
            }
        }

        @media screen and (max-width: 425px) {
            flex-direction: column;
            width: fit-content;
        }
    }
}