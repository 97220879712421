.jenna-input {
    height: 50px;
    margin: 5px auto 60px;
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;

    &-field {
        width: 50%;
        padding: 13px 0 10px 10px;
        border: 2px solid var(--azul);
        border-left: 0;
        flex-grow: 0.4;
        resize: none;
        outline: none;
        overflow-y: hidden;
        text-align: left;
        z-index: 1;

        @media (max-width: 768px) {
            padding-right: 55px;
        }

        &::placeholder {
            padding-top: 0.15em;
            font-size: 0.85em;
            color: #838383;
        }
    }

    &-button-send-message {
        width: 100px;
        padding: 16px 19px 0;
        border-radius: 0px 8px 8px 0px;
        display: flex;
        flex-direction: column;
        background-image: url('/images/plane.svg');
        background-repeat: no-repeat;
        background-size: 18px 14px;
        background-position: 63px 18px;
        background-color: var(--azul);
        box-sizing: border-box;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;

        &-text {
            margin: 0;
        }

        @media (max-width: 768px) {
            width: 50px;
            background-position: 15px 18px;

            &-text {
                display: none;
            }
        }
    }


    &-button-menu {
        width: 50px;
        border: solid 2px var(--azul);
        border-radius: 5px 0px 0px 5px;
        background-image: url('/images/menu.svg');
        background-repeat: no-repeat;
        background-position: center;
        box-sizing: border-box;
        cursor: pointer;

        &--active {
            background-color: var(--azul);
            background-image: url('/images/persistantInactive.svg');
        }
    }

    &-menu {
        width: 222px;
        height: 152px;
        margin: 0 0 60px;
        padding: 0px 20px;
        border: 1px solid rgba(55, 120, 240, 1);
        border-radius: 8px;
        position: absolute;
        bottom: 0;
        background-color: #f0f5fa;
        font-size: 14px;
        z-index: 10001;
        box-sizing: border-box;
        list-style-type: none;

        &>li {
            padding: 15px 0;
            border-bottom: 1px solid rgba(55, 120, 240, 0.28);
            font-weight: bold;
            text-align: left;
            color: var(--azul);
            cursor: pointer;
        }

        &>li:last-child {
            border-bottom: 0;
        }

        &--hidden {
            display: none;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        margin: 5px auto 20px;
      }
      
      
    @media (max-width: 425px) {
        width: 90%;
        margin: 5px 10px 5px 20px;
    }
}