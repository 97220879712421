.jenna-left {

    p,
    a {
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &-edit-button {
        width: 36px;
        height: 34px;
        border-radius: 8px;
        border: solid 1px #f2f2f2;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -21px 14px 0 0;
        cursor: pointer;
    }

    &-user-avatar {
        width: 112px;
        height: 112px;
        margin: 30px auto 0;
    }

    &-candidate-info {
        margin: 25px 12%;

        &-name {
            margin-bottom: 18px;
            font-size: 1.4em;
            text-align: center;
            color: #222222;
        }

        &-item {
            margin-top: 10px;
            display: flex;
            align-items: center;

            p,
            a {
                font-size: 16px;
                color: #1a1a1a;
            }

            svg {
                min-width: 21px;
                min-height: 21px;
                margin-right: 8px;
            }
        }

        &-linkedin {
            height: 50px;
            display: flex;
            align-items: center;

            a:hover {
                color: var(--azul);
            }
        }
    }
}