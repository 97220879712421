.jenna-button {
  padding: 8px 20px;
  border: none;
  border-radius: 35px;
  background-color: var(--azul);
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  color: white;
  outline: none;
  cursor: pointer;

  &-disabled {
    background-color: #9bbbf7;
  }
}