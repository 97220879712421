.jenna-open-positions {
    margin: 20px 0 14px 12px;

    .slick-prev {
        width: 40px;
        height: 40px;
        padding: 0 0 40px 2px;
        border: 1px solid #ddd;
        border-radius: 2px;
        left: 0px;
        top: 48px;
        background: white;
        z-index: 9;

        &::before {
            color: #dcdee3;
            font-size: 30px;
            content: "❮";
        }

        &:hover,
        &:focus {
            color: #dcdee3;
            background-color: white;
        }
    }

    .slick-next {
        width: 40px;
        height: 40px;
        padding: 0 0 40px 2px;
        border: 1px solid #ddd;
        border-radius: 2px;
        right: 0px;
        top: 48px;
        background: white;
        z-index: 9;

        &::before {
            color: #dcdee3;
            font-size: 30px;
            content: "❯";
        }

        &:hover,
        &:focus {
            color: #dcdee3;
            background-color: white;
        }
    }

    .slick-slider {
        width: 100%;
    }

    .slick-track {
        display: flex !important;
    }

    .slick-disabled {
        opacity: 0;
    }
}
