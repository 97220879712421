.jenna-user-avatar-button {
  &-upload {
    border: none;
  }

  &-uploading {
    background-color: #a1c0dd;
  }

  &-uploaded {
    cursor: auto;
  }
}

