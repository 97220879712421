.jenna-footer {
  margin: 18px 0 22px;
  position: relative;
  bottom: 5px;
  transition: all 200ms ease-in-out;
  font-size: 12px;
  align-self: center;

  &-privacy {
    margin-right: 0.5em;
    padding-right: 0.5em;
    border-right: 1px solid #b4b4b4;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    color: #b4b4b4;

  }

  &-privacy:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &-policy {
    margin-right: 0.5em;
    padding-right: 0.5em;
    border-right: 1px solid #b4b4b4;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    color: #b4b4b4;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &-powered-by {
    display: inline-block;
    font-size: 14px;
    text-align: left;
    color: #cbcbcb;
  }

  &-logo-small {
    width: 4.5em;
    height: 0.8em;
    display: inline-block;
    background-image: url('/images/jenna_logo.svg');
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-image: url('/images/jenna_logo_purple.svg');
    }
  }
}

@media (max-width: 768px) {
  .jenna-footer {
    width: 100%;
    margin: 0;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
}

@media (min-width: 769px) {
  .jenna-footer {
    bottom: 70px;
  }
}


@media (max-width: 425px) {
  .jenna-footer {
    &-privacy {
      display: none;
    }

    &-powered-by {
      display: block;
      font-size: 12px;
      letter-spacing: -0.1px;
      color: #48494a;
    }

    &-policy {
      padding-right: 5px;
      font-weight: normal;
      letter-spacing: -0.1px;
      color: #48494a;

      &:hover {
        color: #347af0;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .footer-hidden {
    height: 0px;
    visibility: hidden;
    margin-top: 4px;
  }
}