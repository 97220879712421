.jenna-about-position {
    display: flex;

    &-description {
        width: 70%;
        margin: 0 0 14px 14px;
        border: solid 1px #dcdcdc;
        border-radius: 26px;
        background-color: #ffffff;

        &-name {
            max-width: calc(100% - 17.3em);
            margin-left: 1.3em;
            font-weight: bold;
            line-height: 3.5;
            color: #1a1a1a;

            @media (max-width: 893px) {
                max-width: 90%;
            }

            @media (max-width: 768px) {
                max-width: calc(100% - 17.3em);
            }

            @media (max-width: 455px) {
                max-width: 90%;
            }
        }

        &-text {
            width: calc(100% - 2em);
            min-height: 1em;
            margin: 0 1em 1em;
            font-size: 14px;
            line-height: 1.43;
            white-space: break-spaces;
            text-align: left;
            color: #1f1f1f;
        }
    }
}