.jenna-terms-message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &-show {
    margin: 0 0 14px 14px;
    padding: 12px 20px;
    border: 1px solid #f2f2f2;
    background-color: #fff;
    line-height: 1.4;
    font-weight: normal;
    color: var(--azul);
  }

  &-accept,
  &-reject {
    margin: 0 14px 8px 0;
  }
}