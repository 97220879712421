.jenna-share {
    h5 {
        margin-top: 50px;
        margin-bottom: 0;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 15px;
    }

    &-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        flex-wrap: wrap;
    }

    &-item {
        width: 36px;
        height: 36px;
        margin: 10px 7px 0 0;
        border-radius: 100%;
        background-size: 36px;
        background-repeat: no-repeat;
        background-position: center;
    }

    .lnkdin {
        background-image: url('/images/inShare.svg');

        &:hover {
            background-image: url('/images/inShare_hover.svg');
        }
    }

    .fb {
        background-image: url('/images/fbShare.svg');

        &:hover {
            background-image: url('/images/fbShare_hover.svg');
        }
    }

    .tw {
        background-image: url('/images/twShare.svg');

        &:hover {
            background-image: url('/images/twShare_hover.svg');
        }
    }

    .email {
        background-image: url('/images/eShare.svg');

        &:hover {
            background-image: url('/images/eShare_hover.svg');
        }
    }

    .messanger {
        background-image: url('/images/meShare.svg');

        &:hover {
            background-image: url('/images/meShare_hover.svg');
        }
    }

    .whatsapp {
        background-image: url('/images/waShare.svg');

        &:hover {
            background-image: url('/images/waShare_hover.svg');
        }
    }
}

@media (max-width: 768px) {
    .jenna-share {
        display: none;
    }
}