.jenna-middle {
    height: 100%;

    &-bot-image {
        width: 70px;
        height: 70px;
        margin: 0.5em auto;
        display: block;
    }

    &-conversation {
        height: 100%;
        display: flex;
        flex-direction: column;

        &-messages {
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &-messages>div:first-child {
            margin-top: 5px;
        }
    }
}




/* scroll (only for larger screens)*/
@media (min-width: 769px) {
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bbb;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #aaa;
    }
}

@media (max-width: 768px) {
    .jenna-middle {
        &-bot-image {
            display: none;
        }

        &-conversation {
            &-messages {
                flex-grow: 1;
            }
        }
    }
}

@media (max-width: 425px) {

    input[type='text'],
    input[type='number'],
    input {
        font-size: 16px;
    }
}