.jenna-candidate-info-form {
  min-height: 20vw;
  margin-top: 2.5vw;
  padding: 1vw;
  font-family: 'Rubik', sans-serif;

  &-input {
    width: 100%;
    height: 40px;
    margin-bottom: 14px;
    padding: 0 5px 0 40px;
    border: solid 1px #f2f2f2;
    border-radius: 8px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 18px;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;

    &::placeholder {
      padding-top: 0.15em;
      font-size: 0.85em;
      color: #838383;
    }

    &:focus {
      outline: none;
    }
  }

  .error {
    background-color: lavenderblush;
  }

  &-button {
    width: calc(50% - 7px);
    padding: 12px 20px;
    line-height: 1;
  }

  .cancel {
    margin-right: 14px;
    background-color: var(--lipstick);
  }
}

#personName {
  background-image: url('/images/profile.svg');
}

#personLocation {
  background-image: url('/images/location.svg');
}

#personPhone {
  background-image: url('/images/phone.svg');
}

#personLinkedin {
  background-image: url('/images/linkedin.svg');
}

#personEmail {
  background-image: url('/images/email.svg');
}