.jenna-user-message {
  width: auto;
  max-width: 100%;
  margin: 0 1em 13px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  text-align: left;
  word-wrap: break-word;
  color: #1a1a1a;

  &-avatar {
    width: 40px;
    height: 40px;
    padding: 0;
  }

  &-text {
    margin: 0;
  }
  
  &-text-container {
    width: auto;
    max-width: 75%;
    margin-right: 15px;
    padding: 12px 20px;
    border-radius: 26px;
    background-color: #f0f5fa;
  }

  @media (max-width: 768px) {
    &-text {
        max-width: 100%;
    }
  
    &-avatar {
      .placeholder:hover {
        opacity: 100%;
        background-image: url('/images/plus.svg');
        background-size: 3em;
        background-color: white;
      }
    }
  }
}




