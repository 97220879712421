.jenna-open-position {
    &-wrapper {
        width: 220px;
        margin-right: 15px;
        padding-left: 0px;
        border: solid 1px #dcdcdc;
        border-radius: 26px;
        background-color: #ffffff;
        background-image: none !important;
        overflow: hidden;

        @media (min-width: 769px) {
            width: 250px;
        }
    }

    &-img {
        width: 100%;
        height: 7.5em;
        padding: 0px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 7.5em;
        object-fit: cover;

        @media (max-width: 473px) {
            height: 10.5em;
            padding: 0px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 10.5em;
        }
    }

    &-content {
        width: 100%;
        padding: 0 15px 0;
        float: left;
        position: relative;
        min-height: 1px;
        box-sizing: border-box;

        &-name {
            height: 18px;
            margin: 0.7em 0 0.2em;
            font-size: 0.9em;
            font-weight: bold;
            text-align: left;
            color: #1a1a1a;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

        }

        &-desc {
            height: 36px;
            display: -webkit-box;
            font-size: 0.9em;
            white-space: normal;
            line-height: 1.36;
            text-align: left;
            color: #1f1f1f;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &-actions {
        width: 100%;
        display: flex;
        flex-direction: column;

        &-apply,
        &-about {
            height: 3.2em;
            background-color: #ffffff;
            border: none;
            border-top: solid 1px #dcdcdc;
            border-radius: 3px;
            font-size: 0.9em;
            font-weight: bold;
            color: var(--azul);

            &:hover {
                color: white;
                background-color: #3778f0;
            }
        }
    }
}