.thankYou-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.thankYou-wrapper svg {
  width: 90%;
  max-width: 500px;
  margin-bottom: 30px;
}

.thankYou-wrapper p {
  font-size: 19px;
  font-weight: 600;
  color: rgb(38, 43, 63);
}

@media (max-width: 410px) {
  .thankYou-wrapper p {
    font-size: 4.2vw;
  }
}
