.jenna-bot-message {
    margin: 0px;
    display: flex;

    &-content {
        max-width: 75%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-text {
            padding: 12px 20px;
            margin: 0 0 14px 15px;
            border: solid 1px #f2f2f2;
            border-radius: 26px;
            line-height: normal;
            font-size: 14px;
            background-color: #ffffff;
            color: #1a1a1a;
        }

        &-button {
            margin-left: 16px;
        }

    }
}