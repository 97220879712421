.jenna-image {
  display: flex;

  &-img {
    width: 66%;
    height: 120px;
    margin: 0 0 13px 15px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    background-image: url('https://jennaai-logo-images.s3.amazonaws.com/jenna_logo_small.svg');
  }
}