.jenna-overlay {
  position: fixed;
  width: 200%;
  height: 150%;
  z-index: 1000;
  margin-left: -100%;
  background: rgba(0, 0, 0, 0.6)
}

.jenna-terms-and-conditions {
  width: 0;
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background: white;
  overflow-y: scroll;
  transition: width 0.25s linear, visibility 0.25s linear;
  z-index: 1001;
  font-family: 'Rubik', sans-serif;

  &-show {
    visibility: visible;
    width: 33%;
  }

  &-close {
    width: 40px;
    height: 40px;
    margin: 20px 20px 0 0;
    border: 1px solid #f2f2f2;
    border-radius: 50%;
    position: fixed;
    right: 0;
    background-image: url('/images/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    background-color: white;
    cursor: pointer;
  }


  &-back {
    width: 40px;
    height: 40px;
    margin: 22px 0 0 30px;
    border: 1px solid #f2f2f2;
    border-radius: 50%;
    position:fixed;
    background-image: url('/images/back.svg');
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 11px;
    background-color: white;
    cursor: pointer;
  }

  &-title {
    margin-top:90px;
    padding:0px 0px 0px 30px;
    font-size: 1.17em;
    font-weight: bold;
    text-align: left;
    color:#1a1a1a;
  }

  &-titles {
    padding:20px 30px 0px 30px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color:#1a1a1a;
  }

  &-text{
    padding:0px 30px;
    font-size: 13px;
    text-align: left;
    color:#1a1a1a;
  }

  &-underline{
    text-decoration: underline;
  }

  &-link {
    color: var(--azul);
    cursor:pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &-list {
    margin-top:-5px;
    padding:0px 30px 0px 45px;
    font-size: 13px;
    font-family: 'Rubik', sans-serif;
    text-align: left;
    color:#1a1a1a;
    list-style-type: none;
  }
  
  &-list > li {
    margin-bottom:5px;
    font-size:13px;
  }
}

@media (max-width: 768px) {
  .jenna-terms-and-conditions-show {
    width: 100%;
  }
}





